<template>
    <div class="goodsTag">
        <div class="title">商品标签</div>
        <div class="searchForm">
            <span>查询商品打印：</span>
            <a-input v-model="value" placeholder="请输入商品名称/条码" @pressEnter="searchHandle"/>
            <a-button class="searchBtn" @click="searchHandle">查询</a-button>
        </div>
        <div v-if="checkedGoods && checkedGoods.length" class="goodsBox_1">
            <a-table :columns="columns" :data-source="checkedGoods" :rowKey="(item,index) => index">
                <a slot="num" slot-scope="text,record,index">
                    <div class="jibuqi">
                        <a-icon type="minus" @click="numChange('jian',index)"/>
                        <a-input v-model="record.num" @change="inputChange(index)"/>
                        <a-icon type="plus" @click="numChange('jia',index)" />
                    </div>
                </a>
                <a slot="handle" slot-scope="text,record,index">
                    <span @click="deleteHandle(index)">删除</span>
                </a>
            </a-table>
        </div>
        <div v-else class="goodsBox">请先查询商品，进行打印</div>
        <div class="dayin">
            <a-button class="OkBtn" @click="printHandle">立即打印</a-button>
        </div>
        <a-modal v-model="visible" width="60vw" :closable="false">
            <div class="listData">
                <div>
                    <div class="title">选择商品</div>
                    <div class="left_item" v-for="(item,i) in searchGoods" :key="i">
                        <a-checkbox v-model="item.checked">
                            <span class="goodsname">{{item.goods_name}}</span>
                            <span class="detail" v-if="item.spec_type == 20">{{item.spec_content}}/</span>
                            <span class="detail">{{item.goods_price}}</span>
                        </a-checkbox>
                    </div>
                </div>
                <div>
                    <div class="title">选中商品</div>
                    <div v-for="(item,i) in searchGoods" :key="i">
                        <div class="left_item" v-if="item.checked">
                            <a-checkbox v-model="item.checked">
                                <span class="goodsname">{{item.goods_name}}</span>
                                <span class="detail" v-if="item.spec_content">{{item.spec_content}}/</span>
                                <span class="detail">{{item.goods_price}}</span>
                            </a-checkbox>
                        </div>
                    </div>
                    
                </div>
            </div>
            <template slot="footer">
                <div class="bottomBtn">
                    <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
                    <a-button class="OkBtn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { goodsList } from '@/request/business.js'
export default {
    name:'goodsTag',
    data(){
        return{
            value:'',
            searchGoods:[],
            checkGoods:[],
            checkedGoods:[],
            columns:[
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    align:'center'
                },
                {
                    title: '商品条码',
                    dataIndex: 'goods_no',
                    align:'center'
                },
                {
                    title: '规格',
                    dataIndex: 'spec_content',
                    align:'center'
                },
                {
                    title: '单位',
                    dataIndex: 'goods_unit',
                    align:'center'
                },
                {
                    title: '零售价',
                    dataIndex: 'goods_price',
                    align:'center'
                },
                {
                    title: '打印数量',
                    dataIndex: 'num',
                    scopedSlots: { customRender: 'num' },
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'handle',
                    scopedSlots: { customRender: 'handle' },
                    align:'center'
                },
            ],
            visible:false
        }
    },
    mounted(){

    },
    methods:{
        searchHandle(){
            goodsList({keywords:this.value,page_size:1000}).then(res => {
                if(res.data.status === 200){
                    let list = res.data.data.list.data
                    let newList = []
                    list.forEach(item => {
                        item.checked = false
                        if(item.spec_type == 20){
                            JSON.parse(item.spec_content).forEach(ele => {
                                let obj = JSON.parse(JSON.stringify(item))
                                obj.spec_content = ele.spec_name
                                obj.goods_price = ele.spec_price.toFixed(2)
                                obj.goods_no = ele.goods_no
                                newList.push(obj)
                            })
                        }else{
                            newList.push(item)
                        }
                    });
                    this.searchGoods = newList
                    this.visible = true
                }
            })
        },
        handleOk(){
            this.searchGoods.forEach(item => {
                if(item.checked){
                    this.$set(item , 'num' , 1)
                    this.checkedGoods.push(item)
                }
            })
            this.handleCancel()
        },
        handleCancel(){
            this.visible = false
            this.searchGoods = []
        },
        numChange(type , index){
            if(type === 'jia'){
                this.$set(this.checkedGoods[index] , 'num' , Number(this.checkedGoods[index].num) + 1)
            }else{
                if(this.checkedGoods[index].num > 1){
                    this.$set(this.checkedGoods[index] , 'num' , Number(this.checkedGoods[index].num) - 1)
                }
            }
        },
        inputChange(index){
            console.log(this.checkedGoods[index]);
            let number = this.checkedGoods[index].num
            this.$set(this.checkedGoods[index] , 'num' , this.getInteger(number))
        },
        deleteHandle(i){
            this.checkedGoods.splice(i,1)
        },
        printHandle(){
            console.log(this.checkedGoods);
            this.socketApi.websocketsend({key:"goods_tag", list: this.checkedGoods});
        }
    }
}
</script>
<style lang="less" scoped>
.goodsTag{
    font-size: 1.8vh;
    // padding: 1.2vh 1vw;
    .title{
        font-size: 2.1vh;
        // margin-bottom: 1vh;
        padding: 1.5vh 1vw;
    }
    .searchForm{
        display: flex;
        align-items: center;
        // margin-bottom: 2vh;
        padding: 0 1vw;
        span{
            padding-bottom: 1vh;
        }
    }
    .goodsBox_1{
        height: 70vh;
        margin: 1.2vh 1vw;
        overflow: auto;
    }
    .goodsBox{
        color: #a6a6a6;
        border: 1px solid #a6a6a6;
        text-align: center;
        height: 70vh;
        line-height: 70vh;
        margin: 1.2vh 1vw;
    }
    .dayin{
        padding: 2vh 1vw;
        border-top: 1px solid #e5e5e5;
        margin-top: 2vh;
    }
    .jibuqi{
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-input{
            border:none;
            width: 4vw;
            text-align: center;
        }
        i{
            border: 1px solid #a5a5a5;
            padding: 1vh;
            border-radius: 0.5vh;
        }
    }
}
::v-deep .ant-checkbox-wrapper{
    width: 100%;
    padding: 1vh 1vw;
}
.listData{
    display: flex;
    border:1px solid #a6a6a6;
    >div{
        flex: 1;
        padding: 1.2vh 1vw; 
        height: 62vh;
        overflow: auto;
        .title{
            font-size: 2vh;
            text-align: center;
            font-weight: 700;
        }
        &:first-child{
            border-right: 1px solid #a6a6a6;
        }
        &::-webkit-scrollbar {
            width : 3px; 
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 3px;
            background   : #a6a6a6;
        }
        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 3px;
            background   : #e5e5e5;
        }
        .left_item{
           .goodsname{
                font-size: 2vh;
                margin-right: 1vw;
            }
            .detail{
                // padding: 0 0.2vw;
                font-size: 1.6vh;
                color: #a6a6a6;
            } 
            &:hover{
                background: #ffebe8;
            }
        }
        
    }
}
.bottomBtn{
    display: flex;
    justify-content: center;
    .clearBtn,.OkBtn{
        margin: 0 2vw;
        width: 12vw;
    }
}
::v-deep .ant-table-thead{
    th{
        padding: 1.5vh 1vw;
        font-size: 1.8vh; 
    }
}
::v-deep .ant-table-row{
    td{
        padding: 1.3vh 1vw;
        font-size: 1.8vh;
    }
}
</style>
